import React, { useEffect, useState, useRef } from "react";
import {
  deleteSessionById,
  getUserSessions,
  postUpdateBookAttribute,
} from "../../core/api/Api";
import bg from "../../assets/img/bg/aai-feature-bg.jpeg";
import Modal from "../common/modal/Modal";
import {
  showHideModal,
  showToast,
} from "../../core/common-service/CommonService";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../common/useLangNavigate";

const MyBooks = () => {
  let navigate = useLangNavigate();
  const { t } = useTranslation();
  const mounted = useRef(true);
  const [data, setData] = useState([]);
  const [editBook, setEditBook] = useState({ item: null, index: null });
  const [editedBookName, setEditedBookName] = useState("");

  const modal_confirm_delete_book_id = "modal_confirm_delete_book_id";
  const modal_edit_book_id = "modal_edit_book_id";

  const bookToDeleteResource = useRef({ item: null, index: null });

  const navigateTo = (path) => (e) => {
    e.preventDefault();
    navigate(path);
  };

  const onBookItemClick = (item) => (e) => {
    e.preventDefault();
    navigate("/questions/" + item?.id);
  };

  const onBookDeleteIconClick = (item, index) => (e) => {
    e.preventDefault();
    showHideModal(modal_confirm_delete_book_id);
    bookToDeleteResource.current = { item, index };
  };

  const onBookEditIconClick = (item, index) => (e) => {
    e.preventDefault();
    setEditBook({ item, index });
    setEditedBookName(item.book_name);
    showHideModal(modal_edit_book_id);
  };

  const getUserSessionsData = () => {
    getUserSessions()
      .then((res) => {
        if (mounted.current && res?.data?.success) {
          const data = res?.data?.success;
          setData(data);
        }
      })
      .catch((e) => {});
  };

  const onDeleteBookClick = (e) => {
    e.preventDefault();
    showHideModal(modal_confirm_delete_book_id);
    deleteSessionById({ id: bookToDeleteResource.current.item?.id })
      .then((res) => {
        var tmp_data = [...data];
        tmp_data.splice(bookToDeleteResource.current.index, 1);
        setData(tmp_data);
      })
      .catch((e) => {});
  };

  const onSaveEditBookClick = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        id: editBook.item.id,
        attribute: "book_name",
        value: editedBookName,
      };

      // Call the API function
      await postUpdateBookAttribute(requestData);

      // Update the book name in the local state
      const updatedData = [...data]; // Use the existing state variable `data`, which should be an array
      updatedData[editBook.index].book_name = editedBookName;
      setData(updatedData);

      // Hide the modal
      showHideModal(modal_edit_book_id);

      // Show success toast
      showToast({
        type: "success",
        message: t("book_name_updated_successfully"),
      });
    } catch (error) {
      // Show error toast
      showToast({
        type: "error",
        message: t("failed_to_update_book_name"),
      });
    }
  };

  useEffect(() => {
    mounted.current = true;
    getUserSessionsData();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <section className="aai-features py-100">
      <Modal id={modal_confirm_delete_book_id}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="aai-section-title mb-3 text-center mb-5">
                <p className="section-desc">
                  {t("are_you_sure_you_want_to_delete_this_book")}?
                </p>
                <div className="mt-4">
                  <button
                    className="aai-gradient-outline-btn me-4"
                    onClick={onDeleteBookClick}
                  >
                    {t("yes")}
                  </button>
                  <button
                    className="aai-btn btn-pill-solid"
                    onClick={() => showHideModal(modal_confirm_delete_book_id)}
                  >
                    {t("no")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal id={modal_edit_book_id}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="aai-section-title mb-3 text-center mb-5">
                <p className="section-desc mb-3">{t("edit_book_name")}</p>
                <input
                  type="text"
                  className="form-control"
                  value={editedBookName}
                  onChange={(e) => setEditedBookName(e.target.value)}
                />
                <div className="mt-4">
                  <button
                    className="aai-btn btn-pill-solid"
                    onClick={onSaveEditBookClick}
                  >
                    {t("submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="container">
        <ul className="aai-list-items mt-4">
          <div className="mb-5">
            <a
              href="/prices"
              className="aai-gradient-outline-btn"
              onClick={navigateTo("/")}
            >
              {t("add_new_book")}
            </a>
          </div>
          {data?.map((item, index) => (
            <li
              key={index}
              className="aai-list-item d-flex align-items-center justify-content-between"
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={onBookItemClick(item)}
              >
                <svg
                  className="flex-shrink-0"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="#EDCB88" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.8047 7.52876C18.0651 7.78911 18.0651 8.21122 17.8047 8.47157L10.4714 15.8049C10.2111 16.0653 9.78894 16.0653 9.5286 15.8049L6.19526 12.4716C5.93491 12.2112 5.93491 11.7891 6.19526 11.5288C6.45561 11.2684 6.87772 11.2684 7.13807 11.5288L10 14.3907L16.8619 7.52876C17.1223 7.26841 17.5444 7.26841 17.8047 7.52876Z"
                    fill="#04091E"
                  />
                </svg>
                <span className="aai-list-item-text ms-3">
                  {item?.book_name}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <i
                  className={
                    "fa-solid fa-circle-check " +
                    (item.is_payed ? "text-success" : "text-warning")
                  }
                ></i>

                <button
                  className="aai-breadcrumb-link text-decoration-underline ms-3"
                  onClick={onBookEditIconClick(item, index)}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>

                <button
                  className="aai-breadcrumb-link text-decoration-underline ms-3 d-flex"
                  onClick={onBookDeleteIconClick(item, index)}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default MyBooks;
