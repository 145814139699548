import React, { useRef, useState, useEffect } from "react";
import bg from "../../assets/img/bg/aai-feature-bg.jpeg";
import KRGlue from "@lyracom/embedded-form-glue";
import {
  getPrintingPaymentStatusCallback,
  PAYMENT_GATEWAY_PUBLIC_KEY,
  postCreatePrintingPaymentForm,
} from "../../core/api/Api";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showToast } from "../../core/common-service/CommonService";
import axios from "axios";

const PrintingPaymentPage = () => {
  const mounted = useRef(true);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { file, price, copies, address, postalCode, city } =
    location.state || {};

  useEffect(() => {
    if (paymentStatus === "PAID") {
      showToast({ type: "success", message: "Payment successfully" });

      const timer = setTimeout(() => {
        navigate("/");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [paymentStatus, navigate]);

  useEffect(() => {
    window.scroll(0, 0);
    async function setupPaymentForm() {
      const endpoint = "https://static.scelliuspaiement.labanquepostale.fr";
      const publicKey = PAYMENT_GATEWAY_PUBLIC_KEY;

      try {
        postCreatePrintingPaymentForm({ price })
          .then(async (res) => {
            const response = res?.data?.success;
            if (response) {
              console.log("response ", response);
              const formToken = response?.formToken;
              const orderId = response?.order_id;

              const { KR } = await KRGlue.loadLibrary(
                endpoint,
                publicKey
              ); /* Load the remote library */

              await KR.setFormConfig({
                formToken,
                "kr-language":
                  i18n.language /* to update initialization parameter */,
              });

              await KR.renderElements(
                "#myPaymentForm"
              ); /* Render the payment form into myPaymentForm div */

              await KR.onSubmit(async (res) => {
                console.log("res", res);
                const orderStatus = res.clientAnswer.orderStatus;
                if (orderStatus === "PAID") {
                  setPaymentStatus(orderStatus);
                  getPrintingPaymentStatusCallback(orderId)
                    .then((res) => {
                      console.log("res2 ", res);
                      const response = res?.data?.success;
                      if (response) {
                        showToast({ type: "success", message: response });
                        if (file) {
                          sendFileToBack(file); // Call sendFileToBack
                        }
                      }
                    })
                    .catch((e) => {});
                }
                return false;
              });
            }
          })
          .catch((e) => {
            console.log("ERROR ", e);
          });
      } catch (error) {
        navigate(-1);
        setMessage(error + " (see console for more details)");
        console.log("error ", error);
      }
    }
    setupPaymentForm();
    return () => {
      mounted.current = false;
    };
  }, []);

  const sendFileToBack = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("copies", copies);
    formData.append("address", address);
    formData.append("postal_code", postalCode);
    formData.append("city", city);
    axios
      .post("submitDocxForPrinting", formData)
      .then((response) => {
        console.log("response ", response);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  return (
    <section className="aai-features py-100 payment">
      {paymentStatus === "PAID" && (
        <div className="container pb-100">
          <div className="row">
            <div className="col-12">
              <h2 className="paid-message-header">
                Nous avons confirmé votre abonnement.
              </h2>
            </div>
          </div>
        </div>
      )}
      <div className="form">
        <div className="d-flex align-items-center justify-content-center">
          <div id="myPaymentForm">
            <div className="kr-smart-form" kr-card-form-expanded="true"></div>
          </div>
          <div data-test="payment-message">{message}</div>
        </div>
      </div>
    </section>
  );
};

export default PrintingPaymentPage;
