import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import {
  deleteCookie,
  setCookie,
  buildLocalizedPath,
} from "../../common-service/CommonService";
import { StoreContext } from "../../context/StoreContext";
import { postRevokeToken } from "../../api/Api";
import StickyMenu from "../../../components/common/sticky-menu/StickyMenu.js";
import logo from "../../../assets/img/logo/logo-m.png";
import { useTranslation } from "react-i18next";
import { MARKETING_DOMAIN_URL } from "../../constants/constants.js";
import SideBurgerMenu from "../../../components/_components/SideBurgerMenu/SideBurgerMenu.js";
import useLangNavigate from "../../../components/common/useLangNavigate.js";

const supportedLanguages = ["en", "fr", "es", "it"];

const Header = () => {
  const { isLoggedIn, setIsLoggedIn, mobileMenu, setMobileMenu } =
    useContext(StoreContext);
  const sidebarRef = useRef(null);
  const navigate = useLangNavigate();
  const { t, i18n } = useTranslation();

  const languages = [
    { code: "en", name: "English" },
    { code: "fr", name: "Francais" },
    { code: "es", name: "Español" },
    { code: "it", name: "Italiano" },
  ];

  const activeLang = useMemo(
    () =>
      languages.find((el) => el.code === i18n.language) ?? {
        code: "en",
        name: "English",
      },
    [i18n.language]
  );

  const onLangageChange = (lang_item) => (e) => {
    e.preventDefault();
    setMobileMenu(false);
    if (lang_item.code !== i18n.language) {
      const code = lang_item.code;
      i18n.changeLanguage(code);
      document.body.dir = i18n.dir();
      setCookie("lng", code);
      navigate("", code);
      window.location.reload(false);
    }
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setMobileMenu(false);
    }
  };

  const onNavHeaderClick = (path) => (e) => {
    e.preventDefault();
    navigate(path);
    setMobileMenu(false);
  };

  const handleLogout = () => {
    setMobileMenu(false);
    postRevokeToken()
      .then((res) => {
        deleteCookie("token");
        sessionStorage.removeItem("token");
        setIsLoggedIn(false);
        navigate("/login");
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    document.body.dir = i18n.dir();
    StickyMenu();
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Check the language from the URL or cookies
    const urlLang = window.location.pathname.split("/")[1];
    const cookieLang = document.cookie
      .split("; ")
      .find((row) => row.startsWith("lng="))
      ?.split("=")[1];
    const supportedCodes = languages.map((lang) => lang.code);

    // Determine the language to use
    const initialLang = supportedCodes.includes(urlLang)
      ? urlLang
      : cookieLang || "en";

    if (initialLang !== i18n.language) {
      i18n.changeLanguage(initialLang);
      setCookie("lng", initialLang);
      document.body.dir = i18n.dir(initialLang);
    }
  }, [i18n, languages]);

  return (
    <header className="header-primary aai-sticky">
      <div className="container">
        <nav
          ref={sidebarRef}
          className="navbar navbar-expand-xl justify-content-between"
        >
          <div className="d-flex align-items-center">
            <Link
              to={`${MARKETING_DOMAIN_URL}${
                i18n.language === "it" ? "/it/" : ""
              }`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${MARKETING_DOMAIN_URL}${
                    i18n.language === "it" ? "/it/" : ""
                  }`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <img src={logo} alt="" width="120" />
            </Link>

            {!isLoggedIn && (
              <Link
                to={buildLocalizedPath("/login")}
                onClick={onNavHeaderClick("/login")}
                className="d-block d-lg-none ms-3"
              >
                {t("login")}
              </Link>
            )}
          </div>
          <div
            className={`collapse navbar-collapse ${mobileMenu ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav mx-auto h-100">
              <li className="d-block d-xl-none">
                <div
                  className={`d-lg-none d-flex justify-content-${
                    isLoggedIn ? "center" : "between"
                  } align-items-center`}
                >
                  {!isLoggedIn && (
                    <>
                      <Link
                        to={buildLocalizedPath("/register")}
                        onClick={onNavHeaderClick("/register")}
                        className="aai-gradient-outline-btn"
                      >
                        {t("signup")}
                      </Link>
                    </>
                  )}
                  {isLoggedIn && (
                    <Link
                      to="/"
                      className="aai-gradient-outline-btn"
                      onClick={handleLogout}
                    >
                      {t("logout")}
                    </Link>
                  )}
                </div>
              </li>
              {isLoggedIn && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={onNavHeaderClick("/")}
                  >
                    {t("write_a_new_book")}
                  </Link>
                </li>
              )}
              {isLoggedIn && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={onNavHeaderClick("/my-books")}
                  >
                    {t("my_books")}
                  </Link>
                </li>
              )}
              {isLoggedIn && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={onNavHeaderClick("/manage-subscriptions")}
                  >
                    {t("subscribe.title")}
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={onNavHeaderClick("/gift-card")}
                >
                  {t("gift_card")}
                </Link>
              </li>
              {isLoggedIn && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={onNavHeaderClick("/printing")}
                  >
                    {t("printing")}
                  </Link>
                </li>
              )}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {activeLang?.name}
                </Link>
                <ul className="dropdown-menu">
                  {languages
                    .filter((el) => el.code !== activeLang.code)
                    .map((lang_item, lang_index) => (
                      <li key={"lang-" + lang_index}>
                        <Link
                          onClick={onLangageChange(lang_item)}
                          className="dropdown-item"
                        >
                          <span>{lang_item?.name ?? ""}</span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
          </div>

          <div className="navbar-right d-flex align-items-center gap-4">
            {!isLoggedIn && (
              <div className="align-items-center aai-signup-in-links d-none d-lg-flex">
                <Link
                  to={buildLocalizedPath("/login")}
                  onClick={onNavHeaderClick("/login")}
                >
                  {t("login")}
                </Link>
                <Link
                  to={buildLocalizedPath("/register")}
                  onClick={onNavHeaderClick("/register")}
                  className="aai-gradient-outline-btn"
                >
                  {t("signup")}{" "}
                </Link>
              </div>
            )}
            {isLoggedIn && <SideBurgerMenu onLogout={handleLogout} />}
            <button
              className={`navbar-toggler d-block d-xl-none ${
                mobileMenu ? "collapsed" : ""
              }`}
              onClick={() => setMobileMenu(!mobileMenu)}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded={mobileMenu}
              aria-label="Toggle navigation"
            >
              <span></span>
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
