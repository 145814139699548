import React, { useRef, useEffect, useState } from "react";
import FeatureCard from "../common/feature-card/FeatureCard";
import img from "../../assets/img/features/fi-1.svg";
import Loading from "../common/loading/Loading";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../common/useLangNavigate";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const [data] = useState([]);
  const mounted = useRef(true);

  const goToServiceDetails = (item) => () => {
    if (item?.is_active) {
      navigate("/general-categories/" + item?.id);
    }
  };

  useEffect(() => {
    mounted.current = true;
    navigate("/general-categories/1");
    return;
  });

  if (data?.length === 0) {
    return <Loading />;
  }

  return (
    <section
      className="aai-features py-100"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="aai-section-title mb-3 text-center mb-5">
              <h2 className="section-title">{t("choose_your_service")}</h2>
              <p className="section-desc">
                {t("here_you_can_choose_the_service_you_want_to_start_with")}
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {data?.map((item, index) => (
            <FeatureCard
              key={"service-" + index}
              onClick={goToServiceDetails(item)}
              img={img}
              name={item?.name}
              description={item?.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Home;
