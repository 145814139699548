import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { StoreContext } from "../../core/context/StoreContext";
import { postVerifyRegistration, setDefaultHeader } from "../../core/api/Api";
import { setCookie, showToast } from "../../core/common-service/CommonService";
import { useTranslation } from "react-i18next";
import ShowAfterDelay from "../_components/CountdownText/index.tsx";
import useLangNavigate from "../common/useLangNavigate.js";

const ConfirmRegister = () => {
  const { setIsLoggedIn, setUser } = useContext(StoreContext);
  const location = useLocation();
  const { t } = useTranslation();
  const stateEmail = location?.state?.email;

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState(stateEmail);
  const [loading, setLoading] = useState(false);
  const navigate = useLangNavigate();

  const onOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    const trial_session = localStorage.getItem("trial_session");
    postVerifyRegistration({ email, otp, trial_session })
      .then((res) => {
        setLoading(false);
        const status_code = res?.status;
        const response = res?.data?.success;
        if (status_code === 200) {
          const token = response?.token;
          const user = response?.user;
          if (user?.is_verified) {
            const service_session_id = response?.service_session_id ?? null;
            setDefaultHeader("Bearer " + token);
            setIsLoggedIn(true);
            setUser(user);
            setTimeout(() => {
              setCookie("token", token);
              if (service_session_id) {
                navigate("/questions/" + service_session_id);
                localStorage.removeItem("trial_session");
              } else {
                const from = location.state?.from || "/"; // default to home page if no previous location is found
                navigate(from, "", { state: location.state });
              }
            }, 300);
          } else {
            showToast({ type: "error", message: t("error.wrong_otp") });
          }
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showToast({
      type: "info",
      position: "top-right",
      message: t("otp_receive_code_message") + "!",
      autoClose: 60 * 1000,
    });
    return () => {};
  }, []);

  return (
    <section
      className="aai-signup-in"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
            <div className="position-relative">
              {/* <img src={img} className="img-fluid aai-signup-in-img" alt="" /> */}
            </div>
          </div>
          <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
            <div className="aai-form-wrapper">
              <div className="">
                <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center mb-3">
                  <h2 className="aai-form-title">
                    {t("enter_authentication_code")}
                  </h2>
                </div>
              </div>
              <div>
                <form action="#" onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          className="form-control shadow-none"
                          id="email"
                          name="email"
                          type="email"
                          placeholder={t("your_email_address")}
                          value={email}
                          onChange={onEmailChange}
                          disabled={stateEmail}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <div className="aai-form-container position-relative">
                        <input
                          className="form-control shadow-none"
                          id="auth_code"
                          name="auth_code"
                          value={otp}
                          onChange={onOtpChange}
                          placeholder={t("enter_your_code")}
                        />
                      </div>
                    </div>
                    <ShowAfterDelay delay={10000}>
                      {(showTag) =>
                        showTag && (
                          <p className="call-us-message">
                            {t("if_you_did_not_receive_the_email_call_us_on")}:
                            <a href="tel:+33675670621"> +33 6 75 67 06 21</a>
                          </p>
                        )
                      }
                    </ShowAfterDelay>
                    <div className="col-lg-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          disabled={loading}
                          className="aai-btn btn-pill-solid"
                        >
                          {t("verify")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmRegister;
