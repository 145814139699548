import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useLangNavigate from "../../common/useLangNavigate";
import arrowDesktop from "../../../../src/assets/svg/arrow-Desktop.svg";
import arrowMobile from "../../../../src/assets/svg/arrow-Mobile.svg";
import cardMockup from "../../../../src/assets/img/hero/Credit-Card-Mockup.png";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../core/hooks/useMobile";
import "./GiftCardPayment.css";

const GiftCardPayment = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const navigate = useLangNavigate();
  const isMobile = useIsMobile();
  const location = useLocation();
  const { plan_id } = location.state || {};

  const onEmailChange = (e) => {
    const val = e.target.value;
    setEmail(val);
  };

  const handleContinue = () => {
    // Redirect to the gift-card-form route and pass the email
    navigate("/gift-card-form", "", {
      state: {
        email: email,
        plan_id: plan_id, // Ensure plan_id is passed correctly
      },
    });
  };

  return (
    <section className="gift-card-section">
      <div
        className="steps-container"
        style={{
          display: isMobile ? "block" : "flex",
        }}
      >
        <div className="step">
          <div className="step-header">
            <h1 className="step-number">1</h1>
            <p className="step-text">{t("gift_card_step1")}</p>
          </div>
          {!isMobile && (
            <img src={arrowDesktop} alt="Arrow" className="arrow-icon" />
          )}
        </div>
        <div className="step">
          <div className="step-header">
            <h1 className="step-number">2</h1>
            <p className="step-text">{t("gift_card_step2")}</p>
          </div>
        </div>
        <div className="step">
          <div className="step-header">
            <h1 className="step-number">3</h1>
            <p className="step-text">{t("gift_card_step3")}</p>
          </div>
        </div>
        {isMobile && (
          <img src={arrowMobile} alt="Arrow" className="arrow-icon" />
        )}
      </div>

      <div className="container">
        <div className="col-lg-8">
          <div
            className="d-flex align-items-center"
            style={{
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              className="aai-form-container"
              style={{
                width: isMobile ? "100%" : "70%",
                marginBottom: isMobile ? "1rem" : "0",
              }}
            >
              <input
                className="email-input-container"
                id="email"
                name="email"
                type="email"
                placeholder={t("enter_your_email")}
                value={email}
                onChange={onEmailChange}
              />
            </div>

            <button
              onClick={handleContinue}
              type="button"
              className="validate-btn"
              style={{
                width: isMobile ? "100%" : "auto",
                marginLeft: isMobile ? "0" : "1rem",
              }}
            >
              {t("continue")}
            </button>

            <div
              className="d-flex justify-content-center"
              style={{
                marginTop: isMobile ? "1rem" : "0",
              }}
            >
              <img
                src={cardMockup}
                alt="Card"
                style={{
                  width: isMobile ? "250px" : "600px",
                  height: "300px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardPayment;
