import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../../core/context/StoreContext";
import { useTranslation } from "react-i18next";
import { buildLocalizedPath } from "../../../core/common-service/CommonService";
import "./sideBurgerMenu.css";

const SideBurgerMenu = ({ onLogout }) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useContext(StoreContext);
  const menuRef = useRef(null);

  const { first_name, last_name } = user;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="desktop-burger-menu d-none d-xl-block" ref={menuRef}>
      <button
        className={`burger-icon ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span className="burger-line"></span>
        <span className="burger-line"></span>
        <span className="burger-line"></span>
      </button>
      <div className={`menu-content ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <h5 className="menu-greeting text-truncate">
              <span>{t("welcome")},</span>
              <span className="menu-user-name">
                {[first_name, last_name].join(" ")}
              </span>
            </h5>
          </li>
          <Link
            to={buildLocalizedPath("/profile")}
            type="button"
            className="aai-btn btn-pill-solid menu-link"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("my_personal_information")}
          </Link>
          <Link
            to={buildLocalizedPath("/general-categories/1")}
            type="button"
            className="aai-btn btn-pill-solid menu-link"
            onClick={() => {
              setIsMenuOpen(false);
              onLogout();
            }}
          >
            {t("logout")}
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default SideBurgerMenu;
