// src/components/GiftCardForm.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KRGlue from "@lyracom/embedded-form-glue";
import { showToast } from "../../../core/common-service/CommonService";
import { saveAs } from "file-saver";
import "./GiftCardForm.css";
import {
  PAYMENT_GATEWAY_PUBLIC_KEY,
  postCreateGiftCardPaymentForm,
  getOnGiftCardPaymentCallback,
} from "../../../core/api/Api";
const GiftCardForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { email, plan_id } = location.state || {}; // Ensure plan_id is passed from GiftCardPayment
  const [isPaymentFormRendered, setIsPaymentFormRendered] = useState(false);
  const [message, setMessage] = useState("INITIAL");
  useEffect(() => {
    if (email && plan_id) {
      setupPaymentForm();
    } else {
      setMessage("ERROR");
    }
  }, [email, plan_id]);
  const setupPaymentForm = async () => {
    const endpoint = "https://static.scelliuspaiement.labanquepostale.fr";
    const publicKey = PAYMENT_GATEWAY_PUBLIC_KEY;
    try {
      const res = await postCreateGiftCardPaymentForm({ plan_id, email });
      const response = res?.data?.success;
      if (response) {
        const formToken = response?.formToken;
        const orderId = response?.orderId;
        setMessage("PENDING");
        window.scrollTo({ top: 0, behavior: "smooth" });
        const { KR } = await KRGlue.loadLibrary(endpoint, publicKey);
        await KR.setFormConfig({
          formToken,
          "kr-language": "en", // Set language according to your needs
        });
        await KR.renderElements("#myPaymentForm");
        setIsPaymentFormRendered(true);
        await KR.onSubmit(async (res) => {
          const orderStatus = res.clientAnswer.orderStatus;
          if (orderStatus === "PAID") {
            const callbackRes = await getOnGiftCardPaymentCallback(
              plan_id,
              orderId,
              email
            );
            const successResponse = callbackRes?.data?.success;
            if (successResponse) {
              showToast({ type: "success", message: successResponse?.message });
              saveAs(successResponse?.url, successResponse?.name);
              // Redirect after successful payment
            }
          }
          return false; // Prevent redirection on form submit
        });
      }
    } catch (error) {
      console.error("Error in payment setup:", error);
      setMessage("ERROR");
    }
  };
  return (
    <section className="gift-card-form-section">
      <div className="form">
        <div className="d-flex align-items-center justify-content-center">
          <div id="myPaymentForm">
            <div className="kr-smart-form" kr-card-form-expanded="true"></div>
          </div>
        </div>
        {isPaymentFormRendered && (
          <div className="centered-div">{t("for_more_info_contact_us")}</div>
        )}
      </div>
    </section>
  );
};
export default GiftCardForm;
