import { Link, Navigate, useSearchParams } from "react-router-dom";
import { buildLocalizedPath } from "../../core/common-service/CommonService";
import { useTranslation } from "react-i18next";

function ThankYouPurchase() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  const { t } = useTranslation();

  if (!orderId) {
    return <Navigate to="/" />;
  }

  return (
    <section className="py-100 container">
      <div className="text-center mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow-lg bg-transparent p-4">
              <h1 className="display-4">
                {t("thank_you_purchase.confirmed_your_purchase")}
              </h1>

              <p className="lead mt-3">{t("thank_you_purchase.profit")}</p>

              <div className="mt-4">
                <Link
                  to={buildLocalizedPath("/my-books")}
                  className="aai-btn btn-pill-solid"
                >
                  {t("thank_you_purchase.return")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThankYouPurchase;
